#Roadmap {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Roadmap-h1 {
  color: white;
  text-align: center;
}

.Roadmap-img {
  width: 600px;
}
.Roadmap-item-container {
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 15px;
  margin-top: -400px;
  position: absolute;
  margin-left: 100px;
}
.container-top {
  display: flex;
  justify-content: space-between;
}
.container-logo {
  width: 15px;
}
.container-body {
  margin-top: 10px;
}

.Roadmap-item-container2 {
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 15px;
  margin-top: 300px;
  position: absolute;
  margin-left: 100px;
}
.Roadmap-item-container3 {
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 15px;
  margin-top: -20px;
  position: absolute;
  margin-left: 930px;
}
.Roadmap-item-container4 {
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 15px;
  margin-top: 660px;
  position: absolute;
  margin-left: 930px;
}

@media screen and (max-width: 1321px) {
  #Roadmap {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .Roadmap-h1 {
    color: white;
    text-align: center;
  }

  .Roadmap-img {
    width: 390px;
  }
  .Roadmap-item-container {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: -270px;
    position: absolute;
    margin-left: 100px;
  }
  .container-top {
    display: flex;
    justify-content: space-between;
  }
  .container-logo {
    width: 15px;
  }
  .container-body {
    margin-top: 10px;
  }

  .Roadmap-item-container2 {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: 230px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container3 {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: -20px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container4 {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: 480px;
    position: absolute;
    margin-left: 100px;
  }
}

@media (max-width: 1321px) and (min-width: 700px) {
  .Roadmap-item-container {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: -270px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container2 {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: 230px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container3 {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: -20px;
    position: absolute;
    margin-left: 500px;
  }
  .Roadmap-item-container4 {
    width: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 15px;
    margin-top: 480px;
    position: absolute;
    margin-left: 500px;
  }
}

@media screen and (min-width: 1500px) {
  #Roadmap {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .Roadmap-h1 {
    color: white;
    text-align: center;
    font-size: 36px;
  }

  .Roadmap-img {
    width: 1000px;
  }
  .Roadmap-item-container {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: -650px;
    position: absolute;
    margin-left: 100px;
  }
  .container-top {
    display: flex;
    justify-content: space-between;
    font-size: 36px;
  }
  .container-logo {
    width: 30px;
  }
  .container-body {
    margin-top: 10px;
    font-size: 30px;
    margin-right: 50px;
  }

  .Roadmap-item-container2 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: 440px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container3 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: -20px;
    position: absolute;
    margin-left: 930px;
  }
  .Roadmap-item-container4 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: 1050px;
    position: absolute;
    margin-left: 930px;
  }
}

@media screen and (min-width: 1920px) {
  .Roadmap-item-container {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: -650px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container2 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: 440px;
    position: absolute;
    margin-left: 100px;
  }
  .Roadmap-item-container3 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: -20px;
    position: absolute;
    margin-left: 1355px;
  }
  .Roadmap-item-container4 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: 1050px;
    position: absolute;
    margin-left: 1355px;
  }
}

@media screen and (min-width: 2399px) {
  .Roadmap-item-container {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: -650px;
    position: absolute;
    margin-left: 150px;
  }
  .Roadmap-item-container2 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: 440px;
    position: absolute;
    margin-left: 150px;
  }
  .Roadmap-item-container3 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: -20px;
    position: absolute;
    margin-left: 1680px;
  }
  .Roadmap-item-container4 {
    width: 500px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 30px;
    margin-top: 1050px;
    position: absolute;
    margin-left: 1680px;
  }
}
