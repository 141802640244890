.MainPage {
  height: 100vh;
  background-image: url("./assets/solbotsBG1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.NavBar {
  width: 1320px;
  background-color: #1e1e1e;
  height: 50px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.Navbar-logo-h1 {
  margin-left: 15px;
  color: white;
  font-size: 20px;
}
a {
  text-decoration: none;
}

.Navbar-nav {
  display: flex;
  align-items: center;
}
.Navbar-ul {
  text-decoration: none;
  list-style: none;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-right: 30px;
  font-size: 20px;
}

.Navbar-ul-item > a > h3 {
  color: white;
}

.Navbar-ul-item > a {
  color: white;
}
.Navbar-connect-btn {
  background-color: white;
  border: none;
  width: 150px;
  height: 50px;
  font-size: 20px;
}

.hero {
  display: flex;
  flex-direction: column;
  color: white;
}

.hero-h4-intro {
  margin-top: 100px;
  font-size: 26px;
  margin-bottom: -45px;
}

.hero-h1 {
  font-size: 140px;
  letter-spacing: 10px;
}
.hero-h1 > h1 {
  font-family: "dogicapixel", monospace !important;
}

.hero-h4-outro {
  font-size: 26px;
  margin-left: 750px;
  margin-top: -40px;
}

.buy-me-btn {
  color: black;
  display: flex;
  width: 250px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 100px;
  margin-left: 700px;
}

.buy-me-btn-left {
  margin-left: 20px;
}
.buy-me {
  font-weight: 400;
}
.buy-me-btn-right {
  margin-right: 20px;
}
.buy-solbots {
  font-weight: 900;
  font-size: 28px;
}
.buy-me-btn-img {
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 20px;
}

.dropdown > a,
.dropdown > button {
  background-color: #1e1e1e;
  border: none;
  padding: 0px 20px 0px 10px;
  border-radius: 4px;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.dropdown > a:before,
.dropdown > button:before {
  position: absolute;
  right: 7px;
  top: 12px;
  content: " ";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dropdown input[type="checkbox"] {
  background: url("./assets/downArrow.png");
  color: white;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  opacity: 0;
}

.dropdown input[type="checkbox"]:checked {
  background: url("./assets/downArrow.png");
  color: white;
  position: fixed;
  z-index: +0;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.dropdown ul {
  position: absolute;
  top: 25px;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: 0px;
  list-style: none;
  padding: 4px 0px;
  display: none;
  background-color: #1e1e1e;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
}

.dropdown input[type="checkbox"]:checked + ul {
  display: block;
}

.dropdown ul li {
  display: block;
  padding: 6px 20px;
  white-space: nowrap;
  min-width: 100px;
}

.dropdown ul li:hover {
  background-color: #1e1e1e;
  cursor: pointer;
}

.dropdown ul li a {
  text-decoration: none;
  display: block;
  color: black;
}

.dropdown .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  font-size: 1px;
  padding: 0;
}

ul > a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1321px) {
  .MainPage {
    height: 100vh;
    background-image: url("./assets/solbotsBG1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .NavBar {
    width: 390px;
    background-color: #1e1e1e;
    height: 50px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .Navbar-logo-h1 {
    margin-left: 5px;
    color: white;
    font-size: 20px;
    display: none;
  }
  a {
    text-decoration: none;
  }

  .Navbar-nav {
    display: flex;
    align-items: center;
  }
  .Navbar-ul {
    text-decoration: none;
    list-style: none;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 10px;
    font-size: 20px;
  }

  .Navbar-ul-item > a > h3 {
    color: white;
  }

  .Navbar-ul-item > a {
    color: white;
  }
  .Navbar-connect-btn {
    background-color: white;
    border: none;
    width: 150px;
    height: 50px;
    font-size: 20px;
    display: none;
  }

  .hero {
    display: flex;
    flex-direction: column;
    color: white;
  }

  .hero-h4-intro {
    margin-top: 250px;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .hero-h1 {
    font-size: 34px;
    letter-spacing: 10px;
  }

  .hero-h4-outro {
    font-size: 18px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .buy-me-btn {
    color: black;
    display: flex;
    width: 220px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-top: 150px;
    margin-left: 100px;
  }

  .buy-me-btn-left {
    margin-left: 20px;
  }
  .buy-me {
    font-weight: 400;
  }
  .buy-me-btn-right {
    margin-right: 20px;
  }
  .buy-solbots {
    font-weight: 900;
    font-size: 28px;
  }
  .buy-me-btn-img {
    color: white;
  }
}

@media screen and (min-width: 1500px) {
  .MainPage {
    height: 100vh;
    background-image: url("./assets/solbotsBG1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .NavBar {
    width: 1420px;
    background-color: #1e1e1e;
    height: 100px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Navbar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
  }

  .Navbar-logo-h1 {
    margin-left: 15px;
    color: white;
    font-size: 28px;
  }
  a {
    text-decoration: none;
  }

  .Navbar-nav {
    display: flex;
    align-items: center;
  }
  .Navbar-ul {
    text-decoration: none;
    list-style: none;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-right: 30px;
    font-size: 28px;
  }

  .Navbar-ul-item > a > h3 {
    color: white;
  }

  .Navbar-ul-item > a {
    color: white;
  }
  .Navbar-connect-btn {
    background-color: white;
    border: none;
    width: 150px;
    height: 100px;
    font-size: 28px;
  }

  .hero {
    display: flex;
    flex-direction: column;
    color: white;
  }

  .hero-h4-intro {
    margin-top: 200px;
    font-size: 32px;
    margin-bottom: -45px;
  }

  .hero-h1 {
    font-size: 150px;
    letter-spacing: 10px;
  }

  .hero-h4-outro {
    font-size: 32px;
    margin-left: 580px;
    margin-top: -40px;
  }

  .buy-me-btn {
    color: black;
    display: flex;
    width: 350px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-top: 300px;
    margin-left: 700px;
  }

  .buy-me-btn-left {
    margin-left: 20px;
  }
  .buy-me {
    font-weight: 400;
    font-size: 28px;
  }
  .buy-me-btn-right {
    margin-right: 20px;
  }
  .buy-solbots {
    font-weight: 900;
    font-size: 36px;
  }
  .buy-me-btn-img {
    color: white;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    font-size: 28px;
  }

  .dropdown > a,
  .dropdown > button {
    background-color: #1e1e1e;
    border: none;
    padding: 0px 20px 0px 10px;
    border-radius: 4px;
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 28px;
  }

  .dropdown > a:before,
  .dropdown > button:before {
    position: absolute;
    right: 7px;
    top: 12px;
    content: " ";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }

  .dropdown input[type="checkbox"] {
    background: url("./assets/downArrow.png");
    color: white;
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    opacity: 0;
  }

  .dropdown input[type="checkbox"]:checked {
    background: url("./assets/downArrow.png");
    color: white;
    position: fixed;
    z-index: +0;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .dropdown ul {
    position: absolute;
    top: 25px;
    border: 1px solid #ccc;
    border-radius: 3px;
    left: 0px;
    list-style: none;
    padding: 4px 0px;
    display: none;
    background-color: #1e1e1e;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
  }

  .dropdown input[type="checkbox"]:checked + ul {
    display: block;
  }

  .dropdown ul li {
    display: block;
    padding: 6px 20px;
    white-space: nowrap;
    min-width: 100px;
  }

  .dropdown ul li:hover {
    background-color: #1e1e1e;
    cursor: pointer;
  }

  .dropdown ul li a {
    text-decoration: none;
    display: block;
    color: black;
  }

  .dropdown .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    font-size: 1px;
    padding: 0;
  }
}
