@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
#FaQ {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -150px;
  font-family: "VT323", monospace;
}
.FaQ-h1 {
  color: white;
  margin-bottom: 50px;
  font-size: 56px;
}
.Accordion {
  width: 900px !important;
  background-color: rgb(0, 2, 53) !important;
  color: white !important;
}

.Accordion-header {
  font-family: "VT323", monospace !important;
  font-size: 24px !important;
}

.Accordion-body {
  font-family: "VT323", monospace !important;
}

.Accordion-Icon {
  color: red !important;
}

.Accordion-line {
  border-bottom: 0.5px solid white !important;
}

@media screen and (max-width: 1321px) {
  #FaQ {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -150px;
    font-family: "VT323", monospace;
  }
  .FaQ-h1 {
    color: white;
    margin-bottom: 50px;
    font-size: 56px;
  }
  .Accordion {
    width: 380px !important;
    background-color: rgb(0, 2, 53) !important;
    color: white !important;
  }

  .Accordion-header {
    font-family: "VT323", monospace !important;
    font-size: 24px !important;
  }

  .Accordion-body {
    font-family: "VT323", monospace !important;
  }

  .Accordion-Icon {
    color: red !important;
  }

  .Accordion-line {
    border-bottom: 0.5px solid white !important;
  }
}

@media screen and (min-width: 1500px) {
  #FaQ {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -150px;
    font-family: "VT323", monospace;
  }
  .FaQ-h1 {
    color: white;
    margin-bottom: 50px;
    font-size: 64px;
  }
  .Accordion {
    width: 1200px !important;
    background-color: rgb(0, 2, 53) !important;
    color: white !important;
  }

  .Accordion-header {
    font-family: "VT323", monospace !important;
    font-size: 34px !important;
  }

  .Accordion-body {
    font-family: "VT323", monospace !important;
    font-size: 32px !important;
  }

  .Accordion-Icon {
    color: red !important;
  }

  .Accordion-line {
    border-bottom: 0.5px solid white !important;
  }
}
