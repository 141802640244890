#Team {
  height: 100vh;
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  align-items: center;
}
.Team-h1 {
  color: white;
}
.Team-h2 {
  margin-top: 30px;
  margin-left: 30px;
}
.Team-h3 {
  margin-left: 30px;
  color: #e91874;
}

.Team-bg {
  height: 450px;
  width: 1200px;
  background-image: url("./assets/solbotsBG3.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Team-img {
  width: 250px;
  margin-left: 100px;
}

.Team-container {
  display: flex;
  width: 600px;
  height: 250px;
  color: white;
  background-color: rgba(30, 30, 30, 0.95);
  flex-direction: column;
}
.Team-container-left {
  display: flex;
  flex-direction: column;
}
.Team-container-top {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}
.Team-container-right {
  display: flex;
  flex-direction: column;
}
.TeamTwitter-img {
  padding: 2px;
  background-color: white;
  margin-right: 30px;
}
.Team-container-bottom {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 24px;
}

@media screen and (max-width: 1321px) {
  #Team {
    height: 100vh;
    margin-top: 100px;
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .Team-h1 {
    color: white;
  }
  .Team-h2 {
    margin-top: 30px;
    margin-left: 30px;
  }
  .Team-h3 {
    margin-left: 30px;
    color: #e91874;
  }

  .Team-bg {
    height: 505px;
    width: 380px;
    background-image: url("./assets/solbotsBG3.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
  }

  .Team-img {
    width: 200px;
    margin-left: 0px;
    margin-top: 50px;
  }

  .Team-container {
    display: flex;
    width: 380px;
    height: 340px;
    color: white;
    background-color: rgba(30, 30, 30, 0.95);
    flex-direction: column;
  }
  .Team-container-left {
    display: flex;
    flex-direction: column;
  }
  .Team-container-top {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
  }
  .Team-container-right {
    display: flex;
    flex-direction: column;
  }
  .TeamTwitter-img {
    padding: 2px;
    background-color: white;
    margin-right: 30px;
  }
  .Team-container-bottom {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 17px;
  }
}

@media screen and (min-width: 1500px) {
  #Team {
    height: 100vh;
    margin-top: 100px;
    display: flex;
    flex-direction: column;

    align-items: center;
  }
  .Team-h1 {
    color: white;
    font-size: 80px;
  }
  .Team-h2 {
    margin-top: 30px;
    margin-left: 30px;
  }
  .Team-h3 {
    margin-left: 30px;
    color: #e91874;
  }

  .Team-bg {
    height: 450px;
    width: 1400px;
    background-image: url("./assets/solbotsBG3.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Team-img {
    width: 350px;
    margin-left: 100px;
  }

  .Team-container {
    display: flex;
    width: 800px;
    height: 350px;
    color: white;
    background-color: rgba(30, 30, 30, 0.95);
    flex-direction: column;
  }
  .Team-container-left {
    display: flex;
    flex-direction: column;
  }
  .Team-container-top {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    font-size: 34px;
  }
  .Team-container-right {
    display: flex;
    flex-direction: column;
  }
  .TeamTwitter-img {
    padding: 2px;
    background-color: white;
    margin-right: 30px;
    width: 30px;
  }
  .Team-container-bottom {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 30px;
  }
}
