.Footer {
  height: 100vh;
  background-image: url(./assets/solbotsBG1.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Footer-Mid {
  color: #e91874;
  padding: 50px 70px;
  background-color: #02023a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.logo-mid {
  width: 250px;
}

.logo-bottom {
  font-size: 54px;
}

.Footer-bottom {
  color: #e91874;
  display: flex;
  justify-content: space-between;
  gap: 1000px;
  align-items: center;
  margin-top: 200px;
}

.Social-icons {
  color: #e91874;
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1321px) {
  .Footer {
    height: 100vh;
    background-image: url(./assets/solbotsBG1.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Footer-Mid {
    color: #e91874;
    padding: 50px 70px;
    background-color: #02023a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .logo-mid {
    width: 250px;
  }

  .logo-bottom {
    font-size: 54px;
  }

  .Footer-bottom {
    color: #e91874;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    margin-top: 200px;
  }

  .Social-icons {
    color: #e91874;
    display: flex;
    gap: 10px;
  }
}

@media screen and (min-width: 1500px) {
  .Footer {
    height: 100vh;
    background-image: url(./assets/solbotsBG1.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Footer-Mid {
    color: #e91874;
    padding: 50px 70px;
    background-color: #02023a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .logo-mid {
    width: 250px;
  }

  .logo-bottom {
    font-size: 54px;
  }

  .Footer-bottom {
    color: #e91874;
    display: flex;
    justify-content: space-between;
    gap: 500px;
    align-items: center;
    margin-top: 200px;
  }

  .Social-icons {
    color: #e91874;
    display: flex;
    gap: 20px;
  }
  .Social-icons > img {
    width: 80px;
  }
  .copyright {
    font-size: 36px;
  }
}
