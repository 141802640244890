#AboutUs {
  height: 100vh;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutUs-bg {
  height: 650px;
  background-image: url("./assets/solbotsBG2.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutUs-container {
  height: 300px;
  background-color: rgba(30, 30, 30, 0.95);
  width: 700px;
}
.AboutUs-h1 {
  color: white;
  margin-top: 20px;
  margin-left: 30px;
  font-size: 44px;
}

.AboutUs-p {
  color: white;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 26px;
  margin-right: 30px;
}

.AboutUs-img {
  height: 300px;
  width: 450px;
}

@media screen and (max-width: 1321px) {
  #AboutUs {
    height: 100vh;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AboutUs-bg {
    height: 650px;
    background-image: url("./assets/solbotsBG2.png");
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .AboutUs-container {
    height: 300px;
    background-color: rgba(30, 30, 30, 0.95);
    width: 380px;
  }
  .AboutUs-h1 {
    color: white;
    margin-top: 20px;
    margin-left: 30px;
    font-size: 24px;
  }

  .AboutUs-p {
    color: white;
    margin-top: 10px;
    margin-left: 30px;
    font-size: 20px;
    margin-right: 30px;
  }

  .AboutUs-img {
    height: 280px;
    width: 360px;
  }
}

@media screen and (min-width: 1500px) {
  #AboutUs {
    height: 100vh;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AboutUs-bg {
    height: 850px;
    background-image: url("./assets/solbotsBG2.png");
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AboutUs-container {
    height: 500px;
    background-color: rgba(30, 30, 30, 0.95);
    width: 700px;
  }
  .AboutUs-h1 {
    color: white;
    margin-top: 20px;
    margin-left: 30px;
    font-size: 64px;
  }

  .AboutUs-p {
    color: white;
    margin-top: 10px;
    margin-left: 30px;
    font-size: 36px;
    margin-right: 30px;
  }

  .AboutUs-img {
    height: 500px;
    width: 550px;
  }
}
